import React, { useState } from 'react';
import { Box, Typography, Paper, Chip, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CourseModal from './CourseModal';
import { category } from '../config/subject_mapconfig';
import { useCourses } from '../config/context';
import { CourseContext } from '../config/context';

const CourseList = () => {
  const { courses, selectedUniversity, selectedFaculty, selectedDepartment, isMobile, loading } = useCourses(CourseContext);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenCourse = (course) => {
    if (isMobile) {
      navigate(`/course/${course.id}`, { state: { course, selectedUniversity, selectedFaculty, selectedDepartment } });
    } else {
      setSelectedCourse(course);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCourse(null);
  };

  const calculateStatus = (course) => {
    const votes = course.votes || { easy: 0, normal: 0, hard: 0 };
    const totalVotes = (votes.easy || 0) + (votes.normal || 0) + (votes.hard || 0);
    if (totalVotes === 0) return 'データなし';

    const hardPercentage = (votes.hard / totalVotes) * 100;
    const easyPercentage = (votes.easy / totalVotes) * 100;

    if (hardPercentage > 20) {
      return 'hard';
    } else if (easyPercentage >= 50) {
      return 'easy';
    } else {
      return 'normal';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'easy':
        return '楽単';
      case 'hard':
        return 'エグ単';
      case 'normal':
        return '普通';
      default:
        return 'データなし';
    }
  };

  const handleHelpClick = () => {
    navigate('/help');
  };

  const renderCourses = (categoryName) => {
    const courseList = courses || [];
    const filteredCourses = courseList.filter(course => course.type === categoryName);

    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="200px">
          <CircularProgress />
        </Box>
      );
    }


    return (
      <Box key={categoryName} my={isMobile ? 1 : 2}>
        <Button
          variant="text"
          sx={{
            position: 'fixed',
            bottom: 10,
            right: 10,
            color: 'black',
            textTransform: 'none',
          }}
          onClick={handleHelpClick}
        >
          ヘルプ
        </Button>
        <Typography variant="h5" sx={{ fontWeight: 600, fontSize: isMobile ? '16px' : '30px' }}>
          {categoryName}
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={isMobile ? 1 : 2}>
          {filteredCourses.map((course, index) => (
            <Paper
              key={index}
              onClick={() => handleOpenCourse(course)}
              sx={{
                padding: isMobile ? '5px' : '20px',
                textAlign: 'left',
                cursor: 'pointer',
                position: 'relative',
                borderRadius: '20px',
                minHeight: '60px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: isMobile ? 'calc(50% - 20px)' : '300px',
                border: `3px solid ${calculateStatus(course) === 'easy'
                    ? '#1976d2'
                    : calculateStatus(course) === 'hard'
                      ? '#d32f2f'
                      : calculateStatus(course) === 'normal'
                        ? '#00cc00'
                        : 'transparent'
                  }`,
                '&:hover': {
                  boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                },
              }}
              elevation={3}
            >
              <Typography variant="h6">{course.courseName}</Typography>
              <Typography variant="body1">{course.lecturer}</Typography>
              <Chip
                label={getStatusLabel(calculateStatus(course))}
                sx={{
                  alignSelf: 'flex-end',
                  marginTop: 'auto',
                  backgroundColor:
                    calculateStatus(course) === 'easy'
                      ? '#1976d2'
                      : calculateStatus(course) === 'hard'
                        ? '#d32f2f'
                        : calculateStatus(course) === 'normal'
                          ? '#00cc00'
                          : 'transparent',
                  color: 'white',
                  fontSize: isMobile ? '14px' : '20px',
                  fontWeight: 600,
                  padding: isMobile ? '0px' : '5px',
                }}
              />
            </Paper>
          ))}
          {filteredCourses.length === 0 && (
            <Box display="flex" flexWrap="wrap" gap={isMobile ? 1 : 2}>
              <Paper
                sx={{
                  width: isMobile ? '44%' : '300px',
                  borderRadius: '20px',
                  padding: isMobile ? '5px' : '20px',
                  textAlign: 'left',
                  cursor: 'pointer',
                  border: '1px solid transparent',
                  minHeight: '60px',
                }}
                elevation={3}
              >
                <Typography variant="body1" sx={{ fontSize: isMobile ? '15px' : '20px' }}>
                  科目データが登録されていません。
                </Typography>
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const departmentCategories = category[selectedDepartment] || ['必修科目'];

  return (
    <Box>
      {!selectedFaculty || !selectedDepartment ? (
        <Typography variant="h6" sx={{ textAlign: 'center', marginTop: 2 }}>
          学部・学科を選択してください
        </Typography>
      ) : (
        departmentCategories.map((categoryName) => (
          <React.Fragment key={categoryName}>
            {renderCourses(categoryName)}
          </React.Fragment>
        ))
      )}
      {selectedCourse && !isMobile && (
        <CourseModal
          open={modalOpen}
          handleClose={handleCloseModal}
          course={selectedCourse}
          selectedUniversity={selectedUniversity}
          selectedFaculty={selectedFaculty}
          selectedDepartment={selectedDepartment}
        />
      )}
    </Box>
  );
};

export default CourseList;