import React, {useRef, createContext, useState, useContext, useEffect } from 'react';
import { faculties } from './subject_mapconfig';
import { fetchCourses, fetchGeneralCourses } from './firestoreFunctions';

// Contextの作成
export const CourseContext = createContext();
export const GeneralCourseContext = createContext();

// Providerコンポーネントの作成
export const CourseProvider = ({ children }) => {
    const [courses, setCourses] = useState([]);
    const [selectedUniversity, setSelectedUniversity] = useState('同志社');
    const [selectedFaculty, setSelectedFaculty] = useState('経済学部');
    const [selectedDepartment, setSelectedDepartment] = useState('経済学科');
    const [term, setTerm] = useState('春学期');
    const [loading, setLoading] = useState(false);
    const [selectedFaculties, setFaculties] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [currentOS, setCurrentOS] = useState("iOS");

    const coursesRef = useRef(courses);

    useEffect(() => {
        coursesRef.current = courses;
    }, [courses]);

    const getOS = () => {
        const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
        if (/android/i.test(userAgent)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
        return "unknown";
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize); // 画面サイズ変更時の処理を追加

        return () => {
            window.removeEventListener('resize', handleResize); // クリーンアップ
        };
    }, []);

    useEffect(() => {
        setCurrentOS(getOS());
    }, [])

    

    useEffect(() => {
        const fetchCoursesData = async () => {
            if (!selectedUniversity || !selectedFaculty || !selectedDepartment || !term) {
                // console.log("Skipping fetchCoursesData due to missing dependencies");
                return;
            }

            setCourses([]);

            setLoading(true);
            try {
                const coursesData = await fetchCourses(selectedUniversity, selectedFaculty, selectedDepartment, term);
                setCourses(coursesData);
            } catch (error) {
                setErrorMessage(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCoursesData();
    }, [selectedUniversity, selectedFaculty, selectedDepartment, term]);

    useEffect(() => {
        // selectedUniversityが変更されたときにfacultiesを更新
        const updateFaculties = () => {
            const newFaculties = faculties[selectedUniversity] || [];
            setFaculties(newFaculties);
            setSelectedDepartment('')
        };

        updateFaculties();
    }, [selectedUniversity]);

    return (
        <CourseContext.Provider value={{
            courses,
            selectedUniversity,
            selectedFaculties,
            setSelectedUniversity,
            selectedFaculty,
            setSelectedFaculty,
            selectedDepartment,
            setSelectedDepartment,
            term,
            setTerm,
            setErrorMessage,
            errorMessage,
            loading,
            setLoading,
            isMobile,
            setIsMobile,
            currentOS,
            setCurrentOS,
        }}>
            {children}
        </CourseContext.Provider>
    );
};

export const GeneralCourseProvider = ({ children }) => {
    const [courses, setCourses] = useState([]);
    const [selectedUniversity, setSelectedUniversity] = useState('同志社');
    const [selectedField, setSelectedField] = useState('');
    const [term, setTerm] = useState('春学期');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [, setLoading] = useState(false);
    const [currentOS, setCurrentOS] = useState("iOS");
    

    const coursesRef = useRef(courses);

    useEffect(() => {
        coursesRef.current = courses;
    }, [courses]);

    const getOS = () => {
        const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
        if (/android/i.test(userAgent)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
        return "unknown";
    };

   

    useEffect(() => {
        setCurrentOS(getOS());
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize); // 画面サイズ変更時の処理を追加
        return () => {
            window.removeEventListener('resize', handleResize); // クリーンアップ
        };
    }, []);

    useEffect(() => {
        const fetchGeneralCoursesData = async () => {
            if (!selectedUniversity || !selectedField || !term) {
                // console.log("Skipping fetchCoursesData due to missing dependencies" + selectedUniversity + selectedField);
                return;
            }
    
            setLoading(true);
            try {
                const coursesData = await fetchGeneralCourses(selectedUniversity, selectedField, term);
                if (JSON.stringify(coursesData) !== JSON.stringify(coursesRef.current)) {
                    setCourses(coursesData);
                }
            } catch (error) {
                // console.log(error.message);
                console.log(error.message)
            } finally {
                setLoading(false);
            }
        };
    
        fetchGeneralCoursesData();
    }, [selectedUniversity, selectedField, term]); 

    return (
        <GeneralCourseContext.Provider value={{
            courses,
            selectedUniversity,
            setSelectedUniversity,
            selectedField,
            setSelectedField,
            term,
            setTerm,
            isMobile,
            setIsMobile,
            currentOS,
            setCurrentOS,
        }}>
            {children}
        </GeneralCourseContext.Provider>
    );
};

// カスタムフックでContextを利用
export const useCourses = () => useContext(CourseContext);
export const useGeneralCourses = () => useContext(GeneralCourseContext)